import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import { em, rem, hideVisually } from "polished"
import { debounce } from "lodash"

import Button from "../Button"
import { ReactComponent as SvgTarget } from "../../assets/images/icons/target.svg"
import { ReactComponent as SvgSwitchVertical } from "../../assets/images/icons/switch-vertical.svg"
import useDomId from "../../utils/useDomId"
import useOutsideClick from "../../utils/useOutsideClick"
import useAbortController from "../../utils/useAbortController"
import useWatchGeoPosition from "../../utils/useWatchGeoPosition"
import { __ } from "../../utils/translate"
import { fetchApiTrafficStops } from "../../utils/api"
import { useStore } from "../../store"

const IS_WATCH_POSITION_SUPPORTED =
  !!window.navigator?.geolocation?.watchPosition

const FormField = ({ type, value, onChange, onSwitchValues }) => {
  const store = useStore()
  const { signal } = useAbortController()
  const labelDomId = useDomId()
  const inputDomId = useDomId()
  const [status, setStatus] = useState(`idle`) // idle|active|loading|results
  const [inputValue, setInputValue] = useState(``)
  const [results, setResults] = useState([])
  const [locateStatus, setLocateStatus] = useState(`idle`) // idle|loading|error:empty|error:locate
  const [determineGeoPosition] = useWatchGeoPosition({
    onWatch: (pos) => {
      fetchApiTrafficStops(
        {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        },
        { signal }
      )
        .then((stops) => {
          if (stops.length) {
            onChange(stops[0])
            setLocateStatus(`idle`)
          } else throw Error()
        })
        .catch(() => setLocateStatus(`error:empty`))
    },
    onError: () => setLocateStatus(`error:locate`),
    once: true,
  })

  useEffect(() => {
    setInputValue(value?.name ?? ``)
  }, [value])

  const inputFocus = () => {
    setInputValue(``)
    setStatus(`active`)
    setLocateStatus(`idle`)
  }

  const inputBlur = () => {
    setInputValue(value?.name ?? ``)
  }

  const inputChange = useCallback((e) => {
    setInputValue(e.currentTarget.value)
    const query = e.currentTarget.value.trim()

    if (query) {
      setStatus(`loading`)
      searchResults(query)
    } else {
      setStatus(`active`)
    }
  })

  const searchResults = useCallback(
    debounce((query) => {
      fetchApiTrafficStops({ query }, { signal })
        .then((stops) => {
          if (store.trafficStart || store.trafficFinish) {
            const stopExcludes = [
              store.trafficStart?.id,
              store.trafficFinish?.id,
            ].filter(Boolean)

            stops = stops.filter((s) => !stopExcludes.includes(s.id))
          }

          stops = stops.map((stop) => {
            stop.tempName = stop.name

            if (stop.municipalityId) {
              const municipality = store.findMunicipality(stop.municipalityId)
              if (municipality) stop.tempName += ` (${municipality.name})`
            }

            return stop
          })

          setResults(stops)
          setStatus(`results`)
        })
        .catch(() => {
          setStatus(`results`)
          setResults([])
        })
    }, 400),
    []
  )

  const resultClick = useCallback(
    (e) => {
      const result = results.find((r) => r.id == e.currentTarget.dataset.id)
      setStatus(`idle`)
      if (onChange && result) {
        delete result.tempName
        onChange(result)
      }
    },
    [results]
  )

  const switchClick = () => onSwitchValues && onSwitchValues()

  const locateClick = () => {
    setLocateStatus(`loading`)
    determineGeoPosition()
  }

  const outsideClick = () => {
    setStatus(`idle`)
  }

  const outsideClickRef = useOutsideClick(outsideClick)

  const label = type == `start` ? __(`Departure`) : __(`Arrival`)

  return (
    <Container>
      <Label id={labelDomId} htmlFor={inputDomId} $type={type}>
        <span>{label}</span>

        <abbr title={label} aria-hidden="true">
          {type == `start` ? `A` : `B`}
        </abbr>
      </Label>

      <InputWrap>
        <Input ref={outsideClickRef}>
          <input
            id={inputDomId}
            type="search"
            required="required"
            placeholder={label}
            value={inputValue}
            onChange={inputChange}
            onFocus={inputFocus}
            onBlur={inputBlur}
          />

          <ul
            role="listbox"
            aria-labelledby={labelDomId}
            aria-expanded={status != `idle`}
            aria-live={status != `idle`}
            aria-busy={status == `loading`}
          >
            {status == `active` && (
              <li className="--tip">{__(`E.g.`)} Stockholm Centralstation</li>
            )}

            {status == `loading` &&
              Array.from({ length: 2 }).map((_, i) => (
                <li key={i} className="--loader" aria-hidden="true">
                  <div />
                </li>
              ))}

            {status == `results` &&
              results.map((result) => (
                <li key={result.id} className="--button">
                  <button
                    type="button"
                    onClick={resultClick}
                    data-id={result.id}
                  >
                    {result.tempName}
                  </button>
                </li>
              ))}

            {status == `results` && !results.length && (
              <li className="--empty">{__(`No stops found`)}</li>
            )}
          </ul>
        </Input>

        {locateStatus.startsWith(`error:`) && (
          <Error>
            {
              {
                "error:locate": __(
                  `There was an error detecting your location`
                ),
                "error:empty": __(`No stops found`),
              }[locateStatus]
            }
          </Error>
        )}
      </InputWrap>

      {((type == `start` && IS_WATCH_POSITION_SUPPORTED) ||
        type == `finish`) && (
        <Button
          onClick={type == `start` ? locateClick : switchClick}
          icon={type == `start` ? <SvgTarget /> : <SvgSwitchVertical />}
          type="button"
          color="darkSlateGray"
          size="small"
          loading={locateStatus == `loading`}
          disabled={locateStatus == `loading`}
          title={
            type == `start`
              ? __(`Find the closest stop`)
              : __(`Switch start and finish stops`)
          }
        />
      )}
    </Container>
  )
}

export default FormField

const Container = styled.div`
  display: flex;
  gap: ${em(8)};
`

const Label = styled.label`
  padding-top: ${em(9)};

  span {
    ${hideVisually()}
  }

  abbr {
    ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

    width: ${em(26)};
    height: ${em(26)};
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${em(12)};
    text-align: center;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme, $type }) =>
      theme.colors[$type == `start` ? `seaGreen` : `maximumPurple`]};
  }
`

const InputWrap = styled.div`
  flex-grow: 1;
`

const Input = styled.div`
  position: relative;

  &:has(ul[aria-expanded="true"]) {
    z-index: 10;
  }

  input {
    ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

    width: 100%;
    padding: ${em(8)} 0;
    display: block;
    font-size: max(${em(14)}, 16px);
    border-bottom: 1px solid ${({ theme }) => theme.colors.slateGray};
    outline: none !important;

    &:focus {
      border-color: ${({ theme }) => theme.colors.seaGreen};
    }
  }

  ul {
    width: 100%;
    max-height: ${em(240)};
    margin-top: ${em(6)};
    padding: ${em(8)} ${em(14)};
    position: absolute;
    top: 100%;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.bs.button};
    border-radius: ${({ theme }) => theme.br.small};
    animation: 0.3s ${({ theme }) => theme.easings.outBack};
    animation-name:
      ${({ theme }) => theme.animations.fadeIn},
      ${({ theme }) => theme.animations.slideInY(-5)};

    &[aria-expanded="false"] {
      display: none;
    }

    &::-webkit-scrollbar {
      width: ${rem(5)};
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gunmetal};
      border-radius: ${({ theme }) => theme.br.small};
    }

    li {
      font-size: ${em(12)};

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
      }

      &:not(.--button) {
        padding: ${em(8)} 0;
      }

      &.--tip {
        color: ${({ theme }) => theme.colors.cadet};
      }

      &.--loader {
        div {
          height: ${em(16)};
          border-radius: ${({ theme }) => theme.br.small};
          background-color: ${({ theme }) => theme.colors.lightGray};
          animation: ${({ theme }) => theme.animations.fadeBlink} 1s infinite
            linear;
        }
      }

      &.--button {
        button {
          ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

          width: 100%;
          padding: ${em(8)} 0;
          display: block;

          &:hover {
            color: ${({ theme }) => theme.colors.seaGreen};
          }
        }
      }
    }
  }
`

const Error = styled.p`
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  margin-top: ${em(10)};
  font-size: ${em(12)};
  color: ${({ theme }) => theme.colors.maximumRed};
`
