import React, { useState, useRef, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useMediaQuery } from "@react-hook/media-query"
import styled, { css, keyframes } from "styled-components"
import { em, rgba } from "polished"

import Search from "./Search"
import A11ys from "./A11ys"
import { ReactComponent as SvgA11y } from "../assets/images/icons/a11y.svg"
import { ReactComponent as SvgBadgeCheck } from "../assets/images/icons/badge-check.svg"
import { ReactComponent as SvgChevronDown } from "../assets/images/icons/chevron-down.svg"
import { useStore } from "../store"
import embedParams from "../config/embedParams"
import { quickCategories } from "../config/categories"
import { getCategoryIconSrc } from "../utils/icon"
import { __ } from "../utils/translate"
import mq from "../theme/mq"

const Toolbar = () => {
  const store = useStore()
  const [a11yActive, setA11yActive] = useState(false)
  const a11ysRef = useRef()
  const a11yToggleRef = useRef()
  const isDesktop = useMediaQuery(mq.mobileUp)
  const isMiniguide = [`trip`, `list`].includes(embedParams.strict)

  const categories = useMemo(() => {
    if (
      (isMiniguide && !embedParams.quickCategories) ||
      embedParams.quickCategories === false
    )
      return []

    const ids =
      embedParams.quickCategories && embedParams.quickCategories.length
        ? embedParams.quickCategories
        : quickCategories

    return ids
      .filter(
        (id) =>
          store.findCategory(id) &&
          !store.findInQuery({ type: `category`, value: id })
      )
      .slice(0, 3)
  }, [store.query, store.categories, store.pointsVersion])

  const activeA11yCount = store.query.filter(
    (q) =>
      q.type == `wheelchair-tested` ||
      (q.type == `category` &&
        store.categories.find(
          (c) => c.id == q.value && c.legacyType == `accessibility_type`
        ))
  ).length

  const importanceActive = store.query.find(
    (q) => q.type == `importance` && String(q.value) == `3`
  )

  const categoryClick = (e) => {
    store.addToQuery({ type: `category`, value: e.currentTarget.dataset.id })
    store.removeFromQuery({ type: `importance` })
  }

  const a11yClick = () => {
    setA11yActive((v) => !v)
  }

  const a11ysCloseRequest = () => {
    setA11yActive(false)
  }

  const importanceClick = (e) => {
    if (importanceActive)
      store.removeFromQuery({ type: `importance`, value: `3` })
    else store.addToQuery({ type: `importance`, value: `3` })
  }

  if (
    (!isDesktop && !store.fullscreen) ||
    store.print ||
    (store.queryFailed && embedParams.strict)
  )
    return null

  return (
    <>
      <Container>
        {isDesktop && !store.menu && (
          <li>
            <SearchWrap>
              <Search />
            </SearchWrap>
          </li>
        )}

        {embedParams.importanceControl !== false && (
          <li>
            <Toggle
              onClick={importanceClick}
              $active={importanceActive}
              $kind="importance"
            >
              <SvgBadgeCheck aria-hidden="true" />

              <span>{__(`Editor’s choice`)}</span>
            </Toggle>
          </li>
        )}

        {categories.map((id) => (
          <li key={id}>
            <Toggle onClick={categoryClick} data-id={id} $kind="category">
              <img
                src={getCategoryIconSrc(id, `main`)}
                role="presentation"
                alt=""
              />

              <span>{(store.findCategory(id) || {}).label || id}</span>
            </Toggle>
          </li>
        ))}

        {embedParams.a11YControl !== false && (
          <li ref={a11ysRef}>
            <Toggle
              ref={a11yToggleRef}
              onClick={a11yClick}
              $active={a11yActive || !!activeA11yCount}
              $expanded={a11yActive}
              $kind="a11y"
            >
              <SvgA11y aria-hidden="true" />

              <span>
                {__(`Accessibility`)}

                {!!activeA11yCount && ` • ${activeA11yCount}`}
              </span>

              <SvgChevronDown aria-hidden="true" />
            </Toggle>
          </li>
        )}
      </Container>

      {a11yActive && (
        <A11ys
          referenceEl={a11yToggleRef.current}
          onCloseRequest={a11ysCloseRequest}
        />
      )}
    </>
  )
}

export default observer(Toolbar)

const underlayAnim = keyframes`
  50% { transform: scaleY(1.2) scaleX(1.05); opacity: 0.3; }
`

const Container = styled.ul`
  display: inline-flex;
  align-items: flex-start;
  gap: ${em(10)};

  > li {
    position: relative;

    &:empty {
      display: none;
    }
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  height: ${em(44)};
  padding: ${em(6)} ${em(16)};
  position: relative;
  display: flex;
  align-items: center;
  gap: ${em(10)};
  font-size: ${em(15)};
  letter-spacing: ${em(-0.25)};
  white-space: nowrap;
  border: solid ${({ theme }) => theme.colors.aliceBlue};
  border-width: 1px 0 0 1px;
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.bs.button};

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.seaGreen};
      box-shadow: ${({ theme }) => theme.bs.buttonPressed};
    `}

  &:hover {
    ${({ $active }) =>
      $active
        ? css`
            color: ${({ theme }) => theme.colors.aliceBlue};
          `
        : css`
            background-color: ${({ theme }) => theme.colors.aliceBlue};
          `}
  }

  ${({ $kind }) =>
    $kind == `category` &&
    css`
      img {
        width: ${em(26)};
        flex-shrink: 0;
      }
    `}

  ${({ $kind }) =>
    $kind == `a11y` &&
    css`
      svg {
        width: ${em(16)};
        height: ${em(16)};
        flex-shrink: 0;

        &:last-child {
          width: ${em(12)};
          transition: transform 0.3s ${({ theme }) => theme.easings.default};

          ${({ $expanded }) =>
            $expanded &&
            css`
              transform: rotate(180deg);
            `}
        }
      }
    `}

  ${({ $kind }) =>
    $kind == `importance` &&
    css`
      ${({ $active }) =>
        $active &&
        css`
          border-color: ${({ theme }) => rgba(theme.colors.aliceBlue, 0.5)};
        `}

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: ${em(-3)};
        bottom: ${em(-3)};
        left: ${em(-3)};
        right: ${em(-3)};
        border-radius: inherit;
        background-color: ${({ theme }) => theme.colors.alloyOrange};
        animation: ${underlayAnim} 3s ${({ theme }) => theme.easings.default}
          infinite;

        ${({ $active }) =>
          !$active &&
          css`
            display: none;
          `}
      }

      svg {
        width: ${em(16)};
        height: ${em(16)};
        flex-shrink: 0;
      }
    `}
`

const SearchWrap = styled.div`
  width: ${em(300)};
`
